<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <h2>
          Eigen | Wijs | Ontwikkelen
        </h2>
        <h2>met Humanwise</h2>
      </v-col>

      <v-col cols="12" class="mb-4 text-center">
        Wil je aan de slag met jouw persoonlijke | team | organisatie ontwikkeling?<br>
        Vul dan onderstaande velden in.<br>
        Een van ons neemt dan binnen 24 uur contact met je op.<br>
        Wij vertellen je dan graag over de moelijkheden van deze Eigen | Wijs | Onwikkelen App.<br>
      </v-col>

      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>

      <v-col cols="12" class="bm-4">
        <form v-on:submit.prevent="" v-on:keyup.enter="submit">
          <v-text-field
              v-model="form.organisation"
              label="Organisatie"
              required
          />

          <v-text-field
              v-model="form.firstname"
              label="Voornaam"
              required
          />

          <v-text-field
              v-model="form.lastname"
              label="Achternaam"
              required
          />

          <v-text-field
              v-model="form.email"
              label="Emailadres"
              required
          />

          <v-text-field
              v-model="form.phone"
              label="Telefoonnummer"
              required
          />

          <v-text-field
              v-model="form.address"
              label="Adres"
              required
          />

          <v-text-field
              v-model="form.postalcode"
              label="Postcode"
              required
          />

          <v-text-field
              v-model="form.city"
              label="Woonplaats"
              required
          />

          <v-btn color="accent" class="mr-4" block @click="submit" :loading="loading" :disabled="loading">Versturen</v-btn>
        </form>
      </v-col>
    </v-row>

    <snackbar-text :text="error.message" bg-color="red" text-color="white" v-model="error.show" />
    <snackbar-text :text="success.message" bg-color="green" text-color="white" v-model="success.show" :button="false" />
  </v-container>
</template>

<script>

import SnackbarText from "@/components/snackbar-text";
import apiClient from "@/services/apiClient";

export default {
  name: "Login",
  components: {SnackbarText},
  data: () => ({
    form: {
      organisation: null,
      firstname: null,
      lastname: null,
      email: null,
      phone: null,
      address: null,
      postalcode: null,
      city: null,
    },
    error: {
      show: false,
      message: ""
    },
    success: {
      show: false,
      message: "Je contact aanvraag is verstuurd. We nemen zo spoedig mogelijk contact met je op!"
    },
    loading: false,
  }),
  methods: {
    submit() {
      this.loading = true;

      apiClient.post("contact", this.form).then(response => {
        this.success.show = true;

        this.form = {
          organisation: null,
          firstname: null,
          lastname: null,
          email: null,
          phone: null,
          address: null,
          postalcode: null,
          city: null,
        };
      }).catch((err) => {
        this.error.message = "Aanvraag versturen mislukt! Contolleer of alle velden ingevuld zijn.";

        this.error.show = true;
        this.loading = false;
      });
    },
  },
};
</script>
